html,
button {
  font-size: calc(8px + 1vmin);
}

body,
button {
  font-family: 'Rubik', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  color: #061354;
  background-color: #f9f9f9;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Toastify__toast-container {
  width: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
